// Colors

$primaryColor: #28389a;

$secondaryColor: #f26329;

$accentColor: #f3f3f4;

$font-xs: var(--wp--preset--font-size--x-small);
$font-sm: var(--wp--preset--font-size--small);
$font-md: var(--wp--preset--font-size--medium);
$font-lg: var(--wp--preset--font-size--large);
$font-xl: var(--wp--preset--font-size--x-large);
