body {
	--wp--preset--spacing--15: 1rem;
}

#page-header {
	// height: 427px;
	@media (max-width: 768px) {
		height: auto !important;
	}
	@media (max-width: 960px) {
		.wp-block-columns {
			.wp-block-column {
				&:nth-child(2) {
					order: 4;
					padding-left: var(--wp--preset--spacing--15) !important;
					padding-right: var(--wp--preset--spacing--15) !important;
					padding-top: var(--wp--preset--spacing--30) !important;
					padding-bottom: var(--wp--preset--spacing--30) !important;
				}
				&:last-child {
					order: 0;
					margin: -6px 0;
				}
			}
		}
	}
}
