.landing-page {
	&-hero {
		@media screen and (max-width: 768px) {
			padding: 0 15px !important;
		}
	}
	&-hero-image {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
}
