.page-banner {
	@media (max-width: 768px) {
		p {
			font-size: 1.25rem !important;
		}
	}
}
.has-xx-large-font-size {
	@media (max-width: 768px) {
		font-size: 2rem !important;
	}
}

.hero-banner-text {
	// line-height: 2.5;
	transform: translateX(-80px);
	span {
		display: inline-block;
		background: $secondaryColor;
		padding: 5px 15px;
	}
}

.text-highlight-top {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	margin-bottom: 5px;
}
.text-highlight-bottom {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.is-layout-flow.wp-block-column-is-layout-flow {
	@media (max-width: 1200px) {
		// padding-right: var(--wp--preset--spacing--15) !important;
		// padding-left: var(--wp--preset--spacing--15) !important;
	}
}

.is-style-image-lead-in {
	padding-bottom: var(--wp--preset--spacing--30);
}

:where(.wp-block-columns) {
	margin-bottom: auto;
}
