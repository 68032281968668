// WordPress block styles.

@use 'sass:math';

@import 'slick-carousel/slick/slick-theme.css';
@import 'slick-carousel/slick/slick.css';

@import '../../globalStylesheet.css';

@import '@wordpress/base-styles/mixins';
@import '@wordpress/base-styles/variables';
@import '@wordpress/base-styles/breakpoints';
@import '@wordpress/block-library/src/style';

@import 'variables';

@import 'custom';
@import 'page-elements';
@import 'landing-page';
